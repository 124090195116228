import ZuiApiService from '@zola-helpers/client/dist/es/http/api';

export function update() {}

export function getBabyNav() {
  return () => ZuiApiService.get('/website-nav/web-api/babyNav');
}

export function getNav() {
  return () => ZuiApiService.get('/website-nav/web-api/legacyNav');
}
